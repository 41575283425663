import {IntegrationService} from '../../base/types';

export interface MicrosoftPresenceInstallProps {
  service: IntegrationService.MICROSOFT_PRESENCE;
  data: {
    code: string;
    ignoredEmails?: string[];
  };
}

export interface Settings {
  excludedEmails?: string[];
  includeEmails?: string[];
}

export interface MicrosoftPresenceUpdateProps {
  integrationId: string;
  status?: 'enabled' | 'disabled';
  data?: {
    settings: Settings;
  };
}

export enum MicrosoftPresenceIntegrationPath {
  base = '/presence',
  callback = '/presence/callback',
}

export enum ProcessingUsersType {
  ALL = 'all',
  EXCLUDE = 'exclude',
  INCLUDE = 'include',
}
