import {ProcessingUsersType} from './types';

export const MICROSOFT_PRESENCE_OAUTH_URL = 'https://login.microsoftonline.com/common/oauth2/v2.0/authorize';
export const MICROSOFT_PRESENCE_OAUTH_RESPONSE = 'microsoft-authorization-response';

export const SCOPES = ['https://graph.microsoft.com/.default'];

export const EMAIL_LIST_LIMIT = 250;

export const EMAIL_REGEX = /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/;

export const MICROSOFT_PRESENCE_USERS_PROCESSING_HINTS = {
  [ProcessingUsersType.ALL]:
    'By default, we synchronize the presence of all users for which we found a match in PBX and MS.',
  [ProcessingUsersType.EXCLUDE]:
    'Provide a list of email addresses for users you want to exclude from the synchronization process.',
  [ProcessingUsersType.INCLUDE]:
    'Provide a list of users for whom the synchronization should be enabled. All users that are not in this list will be ignored',
};
