import React, {useState} from 'react';

import {LoadingButton} from '@mui/lab';
import {Box, ToggleButton, ToggleButtonGroup, Typography} from '@mui/material';

import {isEqual} from 'lodash';

import ChipList from '../../../base/components/chipList/ChipList';
import {Integration} from '../../../base/types';
import useConsoleIntegrationPatchMutation from '../../hooks/useConsoleIntegrationPatchMutation';
import useConsoleIntegrations from '../../hooks/useConsoleIntegrations';
import {EMAIL_LIST_LIMIT, EMAIL_REGEX, MICROSOFT_PRESENCE_USERS_PROCESSING_HINTS} from '../constants';
import {ProcessingUsersType, Settings} from '../types';

type props = {
  integration: Integration;
};

function getActiveList(settings: Settings): string[] {
  return settings.includeEmails?.length ? settings.includeEmails : settings.excludedEmails || [];
}

function getProcessingType(settings: Settings): ProcessingUsersType {
  if (!settings.includeEmails?.length && !settings.excludedEmails?.length) {
    return ProcessingUsersType.ALL;
  }

  return settings.includeEmails?.length ? ProcessingUsersType.INCLUDE : ProcessingUsersType.EXCLUDE;
}

function toNewConfig(emails: string[], processingType: ProcessingUsersType): Settings {
  const usersSetting: Settings = {
    excludedEmails: undefined,
    includeEmails: undefined,
  };

  if (processingType === ProcessingUsersType.EXCLUDE) {
    usersSetting.excludedEmails = emails;
  } else if (processingType === ProcessingUsersType.INCLUDE) {
    usersSetting.includeEmails = emails;
  }

  return usersSetting;
}

function isEqualSettings(value: Settings, secondValue: Settings) {
  const keys = ['excludedEmails', 'includeEmails'] as Array<keyof Settings>;

  for (const key of keys) {
    if (!isEqual(value[key], secondValue[key])) {
      return false;
    }
  }

  return true;
}

function ToggleButtonStyled({value, children}: {value: ProcessingUsersType; children: React.ReactNode}) {
  return (
    <ToggleButton value={value}>
      <Box ml={1} sx={{height: '24px', lineHeight: '24px'}}>
        {children}
      </Box>
    </ToggleButton>
  );
}

export default function MicrosoftPresenceUsersSettings({integration}: props) {
  const {
    integrationId,
    data: {settings = {}},
  } = integration;

  const [emails, setEmails] = useState<string[]>(getActiveList(settings));
  const {refetch, isLoading: isIntegrationLoading} = useConsoleIntegrations();
  const [processingType, setProcessingType] = useState(getProcessingType(settings));

  const isChanged =
    !isEqualSettings(toNewConfig(emails, processingType), settings) &&
    (processingType !== ProcessingUsersType.ALL ? Boolean(emails.length) : true);

  const handleChangeType = (event: React.MouseEvent<HTMLElement>, newProcessingType: ProcessingUsersType) => {
    setProcessingType(newProcessingType);
  };

  const onSuccess = (response: any) => {
    const {result} = response;

    setEmails(getActiveList(result.data?.settings));
    refetch();
  };

  const {isLoading, mutate} = useConsoleIntegrationPatchMutation({onSuccess}, true);

  const emailValidation = (value: string, index: number, count: number) => {
    if (!value.match(EMAIL_REGEX)) {
      return 'Invalid email';
    }

    if (count + emails.length > EMAIL_LIST_LIMIT) {
      return `You can add up to ${EMAIL_LIST_LIMIT} emails`;
    }
  };

  const handleChanges = (newEmails: string[]) => {
    setEmails(newEmails);
  };

  const handleSubmit = async () => {
    mutate({
      integrationId,
      data: {
        ...integration.data,
        settings: {
          ...integration.data.settings,
          ...toNewConfig(emails, processingType),
        },
      },
    });
  };

  return (
    <div>
      <Typography color="text.primary" sx={{paddingBottom: 2}}>
        Here you can define which users this integration will apply to.
      </Typography>
      <Box mb={2}>
        <ToggleButtonGroup
          disabled={isLoading}
          size="medium"
          exclusive
          value={processingType}
          onChange={handleChangeType}>
          <ToggleButtonStyled value={ProcessingUsersType.ALL}>All users</ToggleButtonStyled>
          <ToggleButtonStyled value={ProcessingUsersType.EXCLUDE}>Exclude users</ToggleButtonStyled>
          <ToggleButtonStyled value={ProcessingUsersType.INCLUDE}>Include users</ToggleButtonStyled>
        </ToggleButtonGroup>
      </Box>

      <Typography color="text.primary" sx={{paddingBottom: 2}}>
        {' '}
        {MICROSOFT_PRESENCE_USERS_PROCESSING_HINTS[processingType]}{' '}
      </Typography>

      {processingType != ProcessingUsersType.ALL ? (
        <ChipList onChange={handleChanges} items={emails} id="emailsList" label="Email" validation={emailValidation} />
      ) : null}

      <LoadingButton
        sx={{mt: 2}}
        loading={isLoading || isIntegrationLoading}
        disabled={!isChanged}
        variant="contained"
        type="submit"
        size="medium"
        onClick={handleSubmit}>
        Save
      </LoadingButton>
    </div>
  );
}
